/**************************
        General
**************************/


html, body {
    margin:0;
    padding:0;
    // font-family: 'Montserrat', sans-serif;

    font-family: "Roboto Slab", sans-serif;
    font-optical-sizing: auto;

    /*change color of text highlight*/
    ::-moz-selection {
        color: #ebf8f1;
        background: #a81d1d;
    }
  
    ::selection {
        color: #ebf8f1;
        background: #a81d1d;
    }
}

html {
    overflow-x: hidden;
}
body, 
#main {
    background-color: var(--color-bg-primary);
    color: var(--color-text-body);
}

#main {
    overflow: hidden;
}







.container {
    max-width:1200px;
    margin:0 auto;
    padding:0 2rem;

    &.ex {
        padding: 0 3rem;
    }
}
.section {
    padding:1rem;
    z-index: 5;
    position: relative;
}
.light-2 {
    background-color: var(--color-light-2);
}

// .section.gradient {
//     background: var(--gradient-dark);
// }

#main {
    margin-top:4rem;
    min-height: calc(100vh - 4rem);

    &.home {
        margin-top: 0;
    }
}

/**************************
        Typography
**************************/

h1, h2, h3, h4, h5, h6 {
    color:var(--color-text-title);
    font-family: "Amarante", sans-serif;
    font-weight: bold;
    letter-spacing: 0.5px;
    font-weight: 500;
    text-transform: uppercase;
}

h1 {font-size: 4rem;}
h2 {font-size: 3.4rem;}
h3 {font-size: 2.9rem;}
h4 {font-size: 2.25rem; text-transform: uppercase;}


p {
    color: var(--color-text-body);
    line-height: 1.5;
}

.uppercase {
    text-transform: uppercase;
}

.center {
    text-align: center;
}

a, a:visited {
    color: var(--color-text-link);
}


/**************************
        Navigation
**************************/

nav {
    width:100%;
    height:4rem;
    position:fixed;
    top:0;
    box-shadow: 0px 2px 20px var(--color-box-shadow);
    background-color: var(--color-bg-primary);
    /* border-bottom:1px solid var(--color-dark-3); */
    z-index: 500;
    transition: background-color 0.2s ease;



    a {
        box-sizing: border-box;
        color: var(--color-text-body);
        transition: border-color 0.2s ease, color 0.2s ease;

        &:visited {
            box-sizing: border-box;
            color: var(--color-text-body);
        }
        &:hover {
            color:var(--color-text-title);
            border-color:var(--color-text-title);
        }
    }

    h1 {
        margin:0;
        padding:0.8rem 0;
        font-size: 2rem;
        font-weight: 400;
        color: var(--color-dark-2);
    }
    


    &.over-hero {
        background-color: transparent;
        box-shadow:none;
        // border-bottom: 1px solid var(--color-dark-2);

    }

    
}



.nav-content {
    max-width: 1200px;
    margin:0 auto;
    display:flex;
    flex-direction: row;
    justify-content: space-between;
    padding:0 1rem;
    height:100%;
}
.nav-logo {
    position:absolute;

    a {
        text-decoration: none;
    }
}
.nav-items {
    margin: 0 auto;
}

.nav-item {
    margin: 0 1rem;
    display: inline-block;
    padding: 1.3rem 1rem;
    font-size: 1.1rem;
    text-transform: uppercase;
    text-decoration: none;
    text-align: center;
    min-width: 8rem;
    border-bottom: 2px solid transparent;
    color: var(--color-text-body);
}







/**************************
        Buttons
**************************/

.btn {
    padding:0.7rem 1.5rem;
    border-radius: 6px;
    text-decoration: none;
    margin:1rem;
    transition: background-color 0.2s ease, color 0.2s ease, border-radius 0.2s ease;
    background-color: var(--color-btn-primary-background);
    color: var(--color-btn-primary-text);
    font-weight: 500;


    &.primary {
        color: var(--color-btn-primary-text);
        border-radius: var(--border-radius-button) 0 var(--border-radius-button) 0;
    
        &:hover {
            background-color: var(--color-btn-primary-bg-hover);
            border-radius: 0;
        }
    }

    

    &.secondary {
        background-color: transparent;
        color: var(--color-btn-outline);
        border: 2px solid var(--color-btn-outline);
        border-radius: 0 var(--border-radius-button) 0 var(--border-radius-button);
    
        &:hover {
            background-color: var(--color-btn-outline);
            color: var(--color-bg-secondary);
            border-radius: 0;
        }
    }

}






/**************************
    Collapsed Project
**************************/
.collapsed-project {
    transition: transform 0.3s ease; 
    color: var(--color-primary-medium);
    text-decoration: none;
    

    & > div {
        min-width: 500px;
        max-width: 500px;

        height: 275px;

        // padding:1.5rem;
        display:flex;
        flex-direction: column;
        box-shadow: 2px 2px 20px var(--color-box-shadow);
        border-radius: var(--border-radius-card) 0 var(--border-radius-card) 0;
        overflow: hidden;
        margin:2rem 1rem;
        position: relative;
        box-sizing: border-box;
        background-color: var(--color-primary-medium);
        transition: border-radius 0.3s ease; 
        
    
    }

    &:hover {
        transform: scale(1.015);
        & > div {
            border-radius: 0;
        }
    }

    .text-overlay {
        width: 100%;
        padding: 0 0 0 1.5rem;
        position: absolute;
        bottom: 1rem;
    }
    h4 {
        margin-bottom: 0;
        margin-top:1.2rem;
        font-size: 1.4rem;
        font-weight: 500;
    }

    p {
        text-transform: uppercase;
        margin: 0.5rem 0;
    }

    .image-container {
        height: 100%;
        width:100%;
        overflow: hidden;
        position: relative;
        object-fit: cover;

        .language-tag {
            position: absolute;
            color: var(--color-bg-primary);
            top:8px;
            right:8px;
            border-radius: 100vh 0 100vh 100vh;
            padding:4px 8px;
            z-index:10;
            font-size: 0.8rem;
            font-weight: 500;
        }
    }

    img {
        object-fit: cover;
        width:100%;
        height:100%;
    }
}



/**************************
    Collapsed Project
**************************/

.gradient-screen {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 1;
}



/**************************
        Responsive
**************************/


@media all and (max-width:767px) {
    .container {
        padding: 0 1rem;

        &.ex {
            padding: 0 2rem;
        }
    
    }
    .collapsed-project > div {
        max-width: 500px;
        min-width: 250px;
        margin:1.5rem 0;
    }
    .nav-item {
        margin:0;
        padding:1.3rem 0.75rem;
    }

}