

#projectContainer {
    display:flex;
    flex-wrap: wrap;
    justify-content: space-around;
    // background-image: url("../../assets/hexbg.svg");
    background-size: 1200px;
}


/**************************
        Layout
**************************/

.project {
    &.section {
        // background-image: url("../../assets/hexbg.svg");
        background-size: 1200px;
        padding-top:4rem;
        padding-bottom:0;
    }

    h3 {
        text-align: center;
        margin-bottom:1rem;
        // font-size: 2.2rem;
    }

    .header {
        position: relative;

        h4 {
            text-align: center;
            color: var(--color-text-body);
            font-weight: 400;
            margin: 1rem 0;
            font-size: 1.2rem;
            font-family: "Roboto Slab", sans-serif;
        }
    }

    .back {
        position: absolute;
        top: 0;
        font-weight: 500;
        text-decoration: none;
        color:var(--color-text-body);
        transition: color 0.2s ease;

        &:hover {
            color:var(--color-text-title);
        }
    }

    .button-group {
        padding: 1rem 5rem;
        display: flex;
        flex-direction: row;
        justify-content: space-evenly;
        flex-wrap: wrap;
        max-width: 800px;
        margin: 0 auto;
    }

    .project-content {
        display: grid;
        grid-template-columns: 3fr 4fr;
        gap: 3rem;

        .image-section {
            margin-top: 4.5rem;
        }
    }

    .description-section {
        margin: 1rem auto;
        max-width: 1100px;

        h4 {
            font-size: 1.7rem;
            margin-bottom: 0.75rem;
        }
    }
}




// .card.container {
//     background-color: var(--color-light-1);
//     border-top-right-radius: 20px;
//     border-top-left-radius: 20px;
//     padding:1rem 2rem;
//     box-shadow:2px 2px 20px #84809E33;
// }






.styles-module_wrapper__1I_qj {
    z-index: 10 !important;
    top: 4rem !important;
    height: calc(100% - 4rem) !important;
}

.styles-module_close__2I1sI, .styles-module_navigation__1pqAE  {
    /* color: var(--color-dark-1) !important; */
    opacity:0.7 !important;
}
.styles-module_close__2I1sI:hover, .styles-module_navigation__1pqAE:hover  {
    /* color: var(--color-dark-1) !important; */
    opacity:1 !important;
}
.styles-module_image__2hdkJ {
    max-height: 95% !important;
    max-width: 95% !important;
}

/**************************
        Images
**************************/

.images {
    width:100%;
    max-width: 1100px;
    margin:1rem auto;
    // background: var(--color-bg-secondary);
    
    padding: 2px;

    .image-container {
        width:100%;
        height:100%;
        box-sizing: border-box;
        overflow: hidden;
        border:4px solid transparent;
        transition:transform 0.3s ease; 

        &:hover {
            transform: scale(1.01);
        }
    }

    img {
        object-fit: cover;
        width:100%;
        height: 100%;
        cursor: pointer;
    }

    &.layout3a {
        display:grid;
        grid-template-columns: 5fr 1fr;
        grid-template-rows: 1fr 1fr;

        .c0 {
            grid-row: 1 / 3;
        }

        .c1 {
            min-width:300px;
            min-height:300px;
        }
    }

    &.layout2a {
        display:grid;
        grid-template-columns: 1fr 6fr;

        .c0 {
            min-width:300px;
            min-height:300px;
        }
    }

    &.layout2b {
        display:grid;
        grid-template-columns: 2fr 1fr;
    }
}




/* Border radius desktop version */
@media all and (min-width:767px) {
    .images {
        &.layout3a {
            .c0 {
                border-top-left-radius: 0;
                border-bottom-left-radius: var(--border-radius-card);
            }
            .c1 {
                border-top-right-radius: var(--border-radius-card);
            }
            .c2 {
                border-bottom-right-radius: 0;
            }
        }

        &.layout2a .c0, &.layout2b .c0 {
            border-top-left-radius: 0;
            border-bottom-left-radius: var(--border-radius-card);
        }
        &.layout2a .c1, &.layout2b .c1 {
            border-top-right-radius: var(--border-radius-card);
            border-bottom-right-radius: 0;
        }
    }
    
}

@media all and (max-width:1000px) {
    .project {
        &.section {padding-top: 1rem; }

        .project-content {
            display: flex;
            flex-direction: column-reverse;

            .image-section {
                margin-top: 0;
            }
        }
    }
}


/* Border radius and grid layout for mobile version */
@media all and (max-width:767px) {

    .project .project-content {
        gap: 1rem;
    }
    .images {

        &.layout3a {
            display:grid;
            grid-template-columns: 1fr 1fr;
            grid-template-rows: 3fr 1fr;

            .c0 {
                grid-column: 1 / 3;
                border-top-left-radius: 0;
                border-top-right-radius: var(--border-radius-card);
            }

            .c1 {
                border-bottom-left-radius: var(--border-radius-card);
                min-width:100px;
                min-height:100px;
            }

            .c2 {
                border-bottom-right-radius: 0;
            
            }
        }
       

        &.layout2a {
            display:grid;
            grid-template-columns: auto;

            .c0 {
                min-width:unset;
                min-height:unset;
                border-top-right-radius: var(--border-radius-card);
            }
            .c1 {
                border-bottom-left-radius: var(--border-radius-card);
            }
        }
        
        &.layout2b {
            display:grid;
            grid-template-columns: auto;
            .c0 {
                border-top-right-radius: var(--border-radius-card);
            }
            .c1 {
                border-bottom-left-radius: var(--border-radius-card);
            }
        }
    }


    .project {
        .button-group {
            padding-left:0;
            padding-right:0;
        }

        .back {
            top:-1.5rem;
        }
    }

   
}