
/**************************
            Hero
**************************/
.hero {
    padding:7rem 0 9rem;

    .container {
        position: relative;
        padding:0 3rem;
        display:grid;
    }
    h1 {
        margin: 0 0 0.5rem;
        font-weight: 400;
        font-size: 6rem;
    }
    h2 {
        color:var(--color-text-body);
        font-size: 1.3rem;
        font-weight: 400;
    }

    .hero-row {
        display: grid;
        grid-template-columns: 1fr 1fr;
    }

    .hero-image-section {
        display: flex;
        align-items: center;
    }

}



.socials-container {
    margin-top:1.5rem;
    margin-right:1.5rem;
    display: flex;
    flex-direction: column;
    gap: 1.5rem;
}

.socials-contact-container {
    margin-top:1.5rem;
    margin-right:1.5rem;
    display: flex;
    flex-direction: row;
    gap: 1.5rem;
    justify-content: center;
}



.social-icon {
    border-radius: 100vh 100vh 0 100vh;
    color: var(--color-btn-outline);
    // background-color: var(--color-dark-2);
    border: 2px solid var(--color-btn-outline);
    transition: background-color 0.2s ease, color 0.2s ease;
    width:36px;
    height:36px;
    text-align:center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-decoration: none;
    font-size: 1.25rem;

    &:visited {
        color: var(--color-btn-outline);
    }
    &:hover { 
        background-color: var(--color-btn-outline);
        color: var(--color-bg-secondary);
    }
    &:last-child {
        margin-right:0;
    }
    &.github {
        font-size: 1.6rem;
    }
}


.headshot-container {
   
    width: fit-content;
    border-radius: 0 var(--border-radius-card) 0 var(--border-radius-card);
    overflow: hidden;
    
}

.headshot-image {
    width: 100%;
    max-width: 500px;
}


.role-section {
    width: 100%;
    // height: calc(15.5vw * 2);
    // height: 4vw;
    // height: 100%;
    margin: 0;

    position: relative;
    z-index: 4;
    top: -13rem;
    margin-bottom: -16rem;

    .role-text {
        font-family: "Almarai", sans-serif;
        width: 100%;
        height: fit-content;
        margin:0;

        // position: absolute;
        top: -13rem;
        // top: 0;
        line-height: 1;

        opacity: 0.8;
        font-weight: 800;
        color: transparent;
        font-size: 15.5vw; //D E V E L O P E R = 9 characters. 100 vw / 9 chars == 11.11
        text-align: left;
        -webkit-text-stroke: 2px var(--color-btn-outline);
    }
}

/**************************
        Home Content
**************************/
.home-content {
    position: relative;
    top:-2rem;
    border-top-left-radius: 16px;
    border-top-right-radius: 16px;
    z-index:50;
    box-sizing: border-box;
}

/**************************
        About Me
**************************/
.about-me {
    margin-bottom: 4rem;
    margin-top: 4rem;

    h3 {
        margin: 0.5rem 0;
    }

    p {
        max-width: 600px;
    }

    .about-me-container {
        width: fit-content;
        padding: 4rem 8rem;
        background: var(--color-bg-secondary);
        border-radius: 0 var(--border-radius-card) 0 var(--border-radius-card);
    }


    .icon-card-row {
        display: flex;
        justify-content: space-evenly;
        flex-wrap: wrap;
        gap: 2rem;
        padding-top: 2rem;
    
        .icon-card {
            display: flex;
            gap: 2rem;
            position: relative;
            background-color: var(--color-primary-medium);
            padding: 0.5rem 2.5rem;
            max-width: 480px;
            width: 100%;
            margin: 0 1rem;
            align-items: center;

            border-radius: 80px 0 80px 0;
            &.border-reversed {
                border-radius: 0 80px 0 80px;
            }

           

            .icon-container {
                display: flex;
                width: 32px;
                height: 32px;
                align-items: center;
                justify-content: center;
                color: var(--color-text-title);
                fill: var(--color-text-title);
                background-color: var(--color-bg-secondary);
                padding: 0.8rem;
                border-radius: 100vw;
            }

            .text-container {
                padding: 1rem 0;
                p {
                    margin: 0;
                    line-height: 2;
                }
                .title {
                    font-weight: 500;
                    color: var(--color-text-title);
                }
            }
        }
    }
}

/**************************
        Skills
**************************/

.skills {
    padding:8rem 0 6rem;
    
    position: relative;
    box-sizing: border-box;

    h3 {
        margin-bottom: 0.5rem;
    }

    .slanted-bg {
        height:100%;
        width:140%;
        background-color: var(--color-bg-secondary);
        transform: translate(-50%, -50%) rotate(357.5deg);
        z-index:50;
        position:absolute;
        left:50%;
        top:50%;
        box-sizing: border-box;
        box-shadow: 0px 0px 20px var(--color-box-shadow);
    }

    .container {
        position: relative;
        z-index: 75;
        display:grid;
        grid-template-columns: 4fr 3fr;    

        .right {
            margin: auto 0;
        }
    }



    .award-timeline {
        position: relative;
        // padding: 1rem 0;
        display: flex;
        flex-direction: column;
        gap: 1.5rem;

        &::before {
            content: '';
            height: 100%;
            width: 3px;
            // background-color: var(--color-primary-dark);
            background-color: var(--color-bg-primary);
            display: flex;
            position: absolute;
            z-index: 5;
            left: 12px;
            transform: translateX(-50%);
        }
        .award-item {
            display: grid;
            grid-template-columns: 6rem auto;
            position: relative;
            z-index: 10;
            

            .year {
                display:flex;
                background-color: var(--color-primary-medium);
                color: var(--color-text-title);
                font-weight: 400;
                padding: 0.25rem;
                margin-right: 0.75rem;
                border-radius: 100vw;
                height: fit-content;
                align-items: center;
                position: relative;
                top: -0.125rem;
                line-height: 1;

                 &::before {
                    content: '';
                    width: 16px;
                    height: 16px;
                    margin-right: 0.75rem;
                    background-color: var(--color-bg-primary);
                    display: flex;
                    border-radius: 100vw;
                }
            }
            p {
                margin: 0;
            }
        }
    }

}



.icons-container {
    margin: 0 0.5rem 0 auto;
    max-width: 400px;
    display:grid;
    grid-template-columns: auto auto auto;
    position: relative;
    // top:-2.5rem;

    i {
        font-size: 60px;
        /* display:block; */
        text-align: center;
        // color:var(--color-dark-2);
        margin:6px 0;
        transition: color 0.2s ease; 
        &:hover {
            color: var(--color-text-title);
        }
    }
}

#icon-name-tooltip {
    z-index: 20;
    background-color: var(--color-bg-primary);
    opacity: 1;
    font-family: "Roboto Slab", sans-serif;
}


.award-section-break {
    position: relative;
    padding:1.5rem 0;

    hr {
        background-color: var(--color-text-body);
        border:none;
        outline: none;
        height:1.5px;
    }
}

.award-icon {
    fill: var(--color-text-title);
   
    padding: 0.5rem;
    background-color: var(--color-bg-secondary);
    border: 2px solid var(--color-text-body);
    border-radius: 100vh;
    width: 2rem;
    transform: translate(-50%, -50%);
    position:absolute;
    left:50%;
    top:50%;

}


/**************************
        Contact Me
**************************/
.contact-me {
    text-align: center;
    padding-top: 3rem;

    h3 {
        margin-bottom: 0;
    }

    .socials-container {
        justify-content: center;
        margin-top: 1.5rem;
    }

    .social-icon {
        margin:0 .75rem;
        width:48px;
        height:48px;
        font-size: 1.55rem;
    }
}



/**************************
        Responsive
**************************/

@media all and (max-width:900px) {
    .hexagon-in1 {
        right: 1rem;
        top: 6rem;
    }
    .hex-image {
        width: 300px;
        height: 300px;
    }
    .about-me p {
        margin-right:300px;
    }
}


@media all and (max-width:767px) {
    html {
        font-size: 14px;
    }
    .hexagon-in1 {
        left:50%;
        transform: translateX(-50%);
        top:10rem;
    }
    .hex-image {
        width:265px;
        height:265px;
    }
    .hero {
        padding-bottom: 0rem;
    }

    .about-me {
        h3 {
            margin-top:9.5rem;
        }
        p {
            margin-right:0;
        }
    }

    .hero {
        .container {
            padding-left: 2rem;
            padding-right: 2rem;
            grid-template-columns: auto 64px;
        }
        .hero-row {
            display: flex;
            flex-direction: column;
        }
        .hero-image-section {
            flex-direction: column;
        }
    


        .socials-container {
            flex-direction: row;
            align-items: flex-end;
            margin: 1rem 0;
        }

    }
    .role-section {
        top: -1.5rem;
        margin-bottom: -3rem;

        .role-text {
            -webkit-text-stroke: 1px var(--color-btn-outline);
        }
    }
    .about-me {
        margin-bottom:4rem;
        margin-top: 6rem;
        padding: 0;

        .about-me-container {
            width: fit-content;
            padding: 2rem;
            background: var(--color-bg-secondary);
            border-radius: 0 0;
            h3 {
                margin-top: 1rem;
            }
        }
    }
    
    .skills {
        padding: 3rem 0;

        .container {
            grid-template-columns: auto;
        }

        .icons-container {
            top:0;
            margin:2rem auto 1rem;
            grid-template-columns: repeat(6, auto);

            i {
                font-size: 40px;
                margin:8px 0;
            }
        }
    }


    .contact-me {
        padding-top:2rem;
    }
}
